import { useRef } from "react";
import "./portfolio.scss";
import { motion, useScroll, useSpring, useTransform } from "framer-motion";

const items = [
  {
    id: 1,
    title: "E-Commerce app",
    image: "/project1.jpg",
    desc: "Full stack ecommerce website created using MERN stack. It allows creation of a user account with authentication system. A separate admin panel has also been created to add new food items within the app.",
  },
  {
    id: 2,
    title: "Lost and found app",
    image:
      "/project2.png",
    desc: "Full stack lost and found app created using React, NodeJS, Express and PostgreSQL. It allows creation of a user account as well. The user can then post their found items within the app.",
  },
  {
    id: 3,
    title: "CSV file converter",
    image:
      "/project3.png",
    desc: "An app that converts CSV to other file types created using React, AGgrid, NodeJS and Express. The files can be edited in the app itself and then downloaded in the desired file type.",
  },
];

const Single = ({ item }) => {
  const ref = useRef();

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const y = useTransform(scrollYProgress, [0, 1], [-100, 100]);

  return (
    <section>
      <div className="container">
        <div className="wrapper">
          <div className="imageContainer" ref={ref}>
            <img src={item.image} alt="" />
          </div>
          <motion.div className="textContainer" style={{ y }}>
            <h2>{item.title}</h2>
            <p>{item.desc}</p>
            {/* <button>See Demo</button> */}  
            {/* it will be added in the future if all the other apps have been deployed. */}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

const Portfolio = () => {
  const ref = useRef();
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["end end", "start start"],
  });

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
  });

  return (
    <div className="portfolio" ref={ref}>
      <div className="progress">
        <h1>Featured Works</h1>
        <motion.div style={{ scaleX }} className="progressBar"></motion.div>
        {/* scaleX is passed directly here due to framer motion */}
      </div>
      {items.map((item) => (
        <Single item={item} key={item.id} />
      ))}
    </div>
  );
};

export default Portfolio;
