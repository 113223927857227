import SkillContainer from "./skillContainer/SkillContainer";
import "./skills.scss";
import { motion } from "framer-motion";

const frontend_skills = [
  {
    id: 1,
    name: "HTML",
    level: "Intermediate",
  },
  {
    id: 2,
    name: "CSS",
    level: "Basic",
  },
  {
    id: 3,
    name: "Tailwind",
    level: "Basic",
  },
  {
    id: 4,
    name: "Git",
    level: "Basic",
  },
  {
    id: 5,
    name: "Javascript",
    level: "Intermediate",
  },
  {
    id: 6,
    name: "React",
    level: "Intermediate",
  },
  {
    id: 7,
    name: "SCSS",
    level: "Basic",
  },
  {
    id: 8,
    name: "NextJs",
    level: "Basic",
  },
];

const backend_skills = [
  {
    id: 1,
    name: "Node.js",
    level: "Basic",
  },
  {
    id: 2,
    name: "Express.js",
    level: "Intermediate",
  },
  {
    id: 3,
    name: "MongoDB",
    level: "Basic",
  },
  {
    id: 4,
    name: "SQL",
    level: "Intermediate",
  },
  {
    id: 5,
    name: "PostgreSql",
    level: "Intermediate",
  },
  {
    id: 6,
    name: "Python",
    level: "Basic",
  },
  {
    id: 7,
    name: "PHP",
    level: "Basic",
  },
  {
    id: 8,
    name: "JSON Web Tokens",
    level: "Basic",
  },
];

const variants = {
  initial: {
    x: -370,
    y: 100,
    opacity: 0,
  },
  animate: {
    x: 0,
    y: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Skills = () => {
  return (
    <div className="background">
      <motion.div
        className="skills"
        variants={variants}
        initial="initial"
        whileInView="animate"
      >
        <motion.div className="textContainer" variants={variants}>
          <p>
            Equipped with the tools to turn
            <br /> ideas into reality
          </p>
          <hr />
        </motion.div>
        <motion.div className="wrapper" variants={variants}>
          <h1>Skills</h1>
          <div className="container">
            <motion.div className="content" variants={variants}>
              <h3>Frontend development</h3>
              <div className="each_container">
                {frontend_skills.map((skill) => {
                  return (
                    <div id={skill.id}>
                      <SkillContainer
                        name={skill.name}
                        level={skill.level}
                        variants={variants}
                      />
                    </div>
                  );
                })}
              </div>
            </motion.div>
            <div className="content">
              <h3>Backend development</h3>
              <div className="each_container">
                {backend_skills.map((skill) => {
                  return (
                    <div id={skill.id}>
                      <SkillContainer
                        name={skill.name}
                        level={skill.level}
                        variants={variants}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
};

export default Skills;
