import { useState, useEffect } from "react";
import { motion, useSpring } from "framer-motion";
import "./Cursor.scss";

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Use a spring animation for smooth movement
  const springX = useSpring(0, { stiffness: 500, damping: 30 });
  const springY = useSpring(0, { stiffness: 500, damping: 30 });

  useEffect(() => {
    const mouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener("mousemove", mouseMove);
    //returns a cleanup function to remove unwanted action

    return () => {
      window.removeEventListener("mousemove", mouseMove);
    };
  }, []);

  // Update the spring values when the mouse moves
  useEffect(() => {
    springX.set(position.x);
    springY.set(position.y);
  }, [position.x, position.y, springX, springY]);

  return (
    <motion.div
      className="cursor"
      style={{
        translateX: springX, // smoothly follow the x position
        translateY: springY, // smoothly follow the y position
      }}
    ></motion.div>
  );
};

export default Cursor;
